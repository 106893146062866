import {
  cn,
  IconButton,
  IconButtonProps,
} from '@lemonenergy/lemonpie-components'
import { Back } from '@lemonenergy/lemonpie-icons'
import { Link, LinkProps } from '@remix-run/react'
import type { PropsWithChildren } from 'react'

export const headerNav = 'flex flex-row justify-between items-center w-full'

export const PageHeaderBackButton = ({
  className,
  children,
  ...props
}: IconButtonProps) => {
  return (
    <IconButton className={cn(className, '[&]:-mx-[10px]')} {...props}>
      {children ?? <Back size="large" />}
    </IconButton>
  )
}

export const PageHeaderBackLink = ({
  children,
  className,
  ...props
}: LinkProps) => {
  return (
    <IconButton asChild>
      <Link className={cn(className, '[&]:-mx-[10px]')} {...props}>
        {children ?? <Back size="large" />}
      </Link>
    </IconButton>
  )
}

export const PageHeaderNav = ({ children, ...props }: PropsWithChildren) => {
  return (
    <div className={headerNav} {...props}>
      {children}
    </div>
  )
}

export const PageHeader = ({ children, ...props }: PropsWithChildren) => {
  return (
    <div className="flex flex-col p-large gap-small" {...props}>
      {children}
    </div>
  )
}
